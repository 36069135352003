import Fuse from 'fuse.js';

export const FUSE_DEFAULT_SETTINGS = {
  findAllMatches: true,
  ignoreLocation: true,
  shouldSort: true,
  threshold: 0.1,
};

export function fuzzyFactory(list: any[], keys: string[], overrides: any = {}) {
  const options = {
    ...FUSE_DEFAULT_SETTINGS,
    ...overrides,
    keys,
  };

  return new Fuse(list, options);
}
